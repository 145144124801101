import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["from", "to"];

  changed() {
    this.fromTarget.setAttribute("max", this.toTarget.value);
    this.toTarget.setAttribute("min", this.fromTarget.value);
  }
}
