const initializeHeader = () => {
  $(".header-placeholder").remove();
  if (
    $(
      ".header.sticky:not(.header-lg, .header-xl, .absolute-light, .absolute-dark)"
    ).length
  ) {
    $("<div class='header-placeholder'></div>").insertBefore(".header.sticky");
  }
  if ($(".header-lg.sticky:not(.absolute-light, .absolute-dark)").length) {
    $("<div class='header-placeholder-lg'></div>").insertBefore(
      ".header-lg.sticky"
    );
  }
  if ($(".header-xl.sticky:not(.absolute-light, .absolute-dark)").length) {
    $("<div class='header-placeholder-xl'></div>").insertBefore(
      ".header-xl.sticky"
    );
  }

  const wrapper = $(".wrapper");
  const headerMenu = $(".header-menu-wrapper");
  const mToggle = $(".m-toggle");
  const closeBtn = $(".close-button");

  mToggle.on("click", function onToggleClick() {
    if (headerMenu.hasClass("show")) {
      headerMenu.removeClass("show");
      wrapper.removeClass("overlay");
    } else {
      headerMenu.addClass("show");
      wrapper.addClass("overlay");
    }
  });

  closeBtn.on("click", function onCloseClick() {
    if (headerMenu.hasClass("show")) {
      headerMenu.removeClass("show");
      wrapper.removeClass("overlay");
    }
  });
  $(document).on("click", function onDocumentClick(e) {
    if ($(e.target).closest(".header-menu-wrapper, .m-toggle").length === 0) {
      if (headerMenu.hasClass("show")) {
        headerMenu.removeClass("show");
        wrapper.removeClass("overlay");
      }
    }
  });

  function checkSize() {
    if (headerMenu.css("position") === "fixed") {
      headerMenu.prependTo("body");
    } else {
      $(".header>.container, .header>.container-fluid").append(headerMenu);
    }
  }

  // checkSize();
  // $(window).on("resize", checkSize);
};

export default initializeHeader;
