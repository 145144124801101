import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["display"]

    connect() {
      Rails.ajax({
        type: 'get',
        url: this.element.getAttribute("data-address"),
        success: (data) => {
          if (data.detected && data.country_iso_code === 'AT')
          {
            this.displayTarget.style.display = 'flex';
            document.body.classList.add("display-banner");
          }
        }
      });
    }

    close() {
        this.displayTarget.remove();
        document.body.classList.add("display-banner");
    }

    disconnect() {
      document.body.classList.remove("display-banner");
    }
}