/* eslint-disable import/first */
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

window.$ = require("jquery");

// require.context("../fonts/frontend", true, /\.(eot|ttf|woff|woff2|otf)$/i);

import "bootstrap";
import "simplebar";
// import "@fortawesome/fontawesome-free/js/all";
// import "node-waves";
import "vanilla-lazyload";
// import "jquery.steps";
import "bootstrap-maxlength";
// import "cookieconsent";
// import sal from 'sal.js';
import "owl.carousel";
import "magnific-popup";
// import "aos/dist/aos";
import "toastr";
import "jquery.easing";
// import "datatables.net";
// import "datatables.net-responsive/js/dataTables.responsive";

// import "../src/plugins/jquery.steps";

import "../src/frontend.scss";
import "../src/frontend/pages/index";

import "controllers";

import "select2";
import "select2/dist/js/i18n/de";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// window.$ = $;
window.Rails = Rails;
